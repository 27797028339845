<template>
    <!-- invisible background to dismis the info -->
    <div class="help-bg" @click="toggleHint" v-if="show_hint"></div>

    <div class="relative  flex">
        <SVGInfo :height="iconHeight" :width="iconWidth" @click="toggleHint" class="clickable" />
        <div :class="{
            infoText: true,
            'top-left': position === 'top-left',
            'top-right': position === 'top-right',
            'bottom-left': position === 'bottom-left',
            'bottom-right': position === 'bottom-right',

        }" v-if="show_hint">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { SVGInfo } from "@/components/SVG/"
import eventbus from "@/eventbus.js"
import idGen from "@/mixins/idGen"

export default {
    name: "InfoButton",
    // idGen generates a new unique ID, to use with events
    mixins: [idGen],
    components: {
        SVGInfo,
    },
    props: {
        text: { type: String },
        iconHeight: { type: Number, default: 22 },
        iconWidth: { type: Number, default: 22 },
        position: { type: String, default: 'bottom-left' }
    },
    data() {
        return {
            show_hint: false,
        }
    },
    methods: {
        toggleHint() {
            this.show_hint = !this.show_hint;
            if (this.show_hint) {
                // Send with UID of element, to make sure we don't catch our own events
                eventbus.$emit("infoClick", { sender: this.ucid });
            }
        }
    },
    mounted() {
        eventbus.$on("infoClick", (obj) => {
            if (obj.sender !== this.ucid)
                this.show_hint = false
        })
    },
    beforeUnmount() {
        eventbus.$off("infoClick");
    }
}
</script>

<style>
.clickable {
    cursor: pointer;
}

.help-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
}



.infoText {
    @apply absolute z-50 bg-white text-greenblue p-5 border-greenblue border-2 text-base shadow-md max-w-96 max-h-96 overflow-y-auto text-balance;
    z-index: 105;
}

.infoText.top-right {
    top: var(--std-padding);
    left: 18px;
    transform: translateY(-100%);
    border-radius: var(--std-radius) var(--std-radius) var(--std-radius) 0;
}

.infoText.top-left {
    top: -10%;
    right: 18px;
    transform: translateY(-100%);
    border-radius: var(--std-radius) var(--std-radius) 0 var(--std-radius);
}

.infoText.bottom-left {
    top: 90%;
    right: 18px;
    border-radius: var(--std-radius) 0 var(--std-radius) var(--std-radius);
}

.infoText.bottom-right {
    top: calc(100% - var(--std-padding));
    left: 18px;
    border-radius: 0 var(--std-radius) var(--std-radius) var(--std-radius);
}
</style>