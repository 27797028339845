import store from "@/store/";
import axios from "axios";
import { getBaseUrl } from "./util";

const BASE_URL = getBaseUrl();

// login:
//	-> post username, login, referrer
//	-> store response ({accessToken: [TOKEN], refreshToken: [TOKEN]})
//		 in localStorage
async function authLogin(user, referrer) {
  return axios
    .post((store.baseAPIUrl || BASE_URL) + "auth/login", {
      name: user.name,
      password: user.password,
      referrer: referrer,
    })
    .then((response) => {
      if (response.data.accessToken && response.data.refreshToken) {
        // this sets userTokens: {accessToken: [TOKEN], refreshToken: [TOKEN]}
        let tokens = {
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        };
        localStorage.setItem("userTokens", JSON.stringify(tokens));
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response.data;
    });
}
// logout: remove jwt tokens and user data from localstorage
function authLogout() {
  console.log("Logging out...");
  localStorage.removeItem("userTokens");
  localStorage.removeItem("user");
}
/**
 * Func authHeader: check if there is an access token in localstorage;
 *                  if so, return it. Else, return empty object.
 * @returns object: Authorization header object. Empty obj if no tokens.
 */
function authHeader() {
  let tokens = JSON.parse(localStorage.getItem("userTokens"));
  if (tokens && tokens.accessToken) {
    return { Authorization: "Bearer " + tokens.accessToken };
  } else {
    return {};
  }
}
/**
 * Func refreshHeader: check if there is a refresh token in localstorage;
 * if so, return it. Else, return empty object.
 */
function refreshHeader() {
  let tokens = JSON.parse(localStorage.getItem("userTokens"));
  if (tokens && tokens.refreshToken) {
    return { Authorization: "Bearer " + tokens.refreshToken };
  } else {
    return {};
  }
}

/**
 * Func refreshToken: try to refresh tokens. If fail, return response status.
 */
function refreshToken() {
  return new Promise((resolve, reject) => {
    const bearer = refreshHeader();
    if (!bearer.Authorization) reject("No existing tokens found");
    return axios
      .post(
        (store.baseAPIUrl || BASE_URL) + "auth/refresh",
        {}, // No body should be necessary for a refresh
        { headers: bearer } // config object; only the Authorization... is set
      )
      .then((response) => {
        if (response.data.accessToken && response.data.refreshToken) {
          // this sets userTokens: {accessToken: [TOKEN], refreshToken: [TOKEN]}
          localStorage.setItem("userTokens", JSON.stringify(response.data));
          resolve(response.data);
        }
        reject({ status: -1, error: "no valid tokens received from server" });
      })
      .catch((err) => {
        try {
          if (err.response && err.response.status === 403) {
            console.log("Failed to refresh token");
          }
        } catch (err) {
          console.log("Error fetching tokens: " + err);
        }
        if (err.response && err.response.status) {
          reject({
            status: err.response.status,
            error: "Error fetching tokens from server",
          });
        } else {
          reject({ status: "", error: "Error fetching tokens from server" });
        }
      });
  });
}
/**
 * Func trySecureAxions(recursively) tries and re-tries to do an authenticated REST call
 * using auth tokens
 */
function trySecureAxios(method, url, payload, retries, responseType) {
  return new Promise((resolve, reject) => {
    // check if we have access, refresh tokens
    const authBearer = authHeader();
    const refreshBearer = refreshHeader();
    // if both tokens are present, go ahead. Else, something is wrong.
    if (!authBearer || !refreshBearer) {
      return reject({ status: -1, error: "no valid tokens in storage." });
    }
    // Axios call, with authorization header. If this fails, a request to refresh
    // should be fired, followed by a new request w new token.
    return axios({
      method: method,
      url: BASE_URL + url,
      data: payload,
      headers: authBearer,
      responseType: responseType,
    })
      .then((response) => {
        // If response is good (200) or redirect (300)
        if (response.status < 400 && response.status >= 200) {
          if (response.data)
            return resolve({ status: response.status, data: response.data });
        } // End if response status
        // Finally, if tokens present but response code is bad or no response.data: reject.
        return reject({
          status: response.status,
          err: "invalid response from server",
        });
      })
      .catch((err) => {
        // Re-try with new headers, if error 403: Forbidden (/unauthorized)
        console.log("Error: " + err);
        if (err.response.status != 403)
          return reject({
            status: err.response.status,
            error: "Error, not 403 -- not retrying authentication",
          });

        refreshToken()
          .then((data) => {
            // If data received, we should have new tokens.
            // So, retry, *if* we're not over our retry-limit yet.
            if (data.accessToken && data.refreshToken) {
              trySecureAxios(method, url, payload, retries - 1, responseType)
                // If this re-try succeeds, accept and pass on the result. Else, reject.
                .then((data) => {
                  return resolve(data);
                })
                .catch((err) => {
                  return reject(err);
                }); // End then/catch trySecureAxios recurse
            } // End if tokens present in refreshtoken
          })
          .catch((err) => {
            return reject(err);
          }); // End then/catch refreshTokens
      }); // End catch axios call
  }); // End return promise
}

export {
  authHeader, authLogin,
  authLogout, refreshHeader,
  refreshToken,
  trySecureAxios
};

