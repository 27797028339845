const TEXT = require("@/util/tr.json");

export function getBaseUrl() {
  // read api url from path ../.env
  let baseUrl = process.env.VUE_APP_MAIN_API_URL || 'https://sim.sempl.energy/';
  if (!baseUrl.endsWith("/")) {
    baseUrl += "/";
  }
  return baseUrl;
}

export function langSelect(obj, lang) {
  if (!obj) {
    return '';
  }

  if (!lang && typeof obj !== "object") {
    if (typeof obj !== "object") {
      return obj;
    }
    return obj[Object.keys(obj)[0]];
  }

  // If a simple string is passed, just return the string
  if (typeof obj === "string") return obj;
  // If language can't be found, return the first item
  if (obj[lang] === undefined) return obj[Object.keys(obj)[0]];

  return obj[lang];
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      // objects can be proxied, so we need to check if they are objects
      if (typeof object1[key] === "object" && typeof object2[key] === "object") {
        if (!this.shallowEqual(object1[key], object2[key])) {
          return false;
        }
      }
      else
        return false;
    }
  }
  return true;
}

export function formatImgUrl(url) {
  if (url === undefined) return url;
  if (url.startsWith("http")) return url;
  // console.log(
  //   `[formatImgUrl] -- Returning: ${getBaseUrl() + "content/getImage/" + url}`
  // );
  return getBaseUrl() + "content/getImage/" + url;
}

export function getPDFRequest(
  req_obj,
  calc_result,
  total_obj,
  interval_obj,
  translate_arr,
  language
) {
  // JSON stringify and parse to copy object
  let new_req_obj = JSON.parse(JSON.stringify(req_obj));
  let new_calc_result = JSON.parse(JSON.stringify(calc_result));
  for (let key in total_obj) {
    let total_params = total_obj[key];
    // Get the right element from calc_result, and reduce its elements
    // with the function defined in total_obj
    let total = Object.values(calc_result[total_params.key]).reduce(
      total_params.reduce_function
    );
    new_calc_result[key] = total;
  }

  // Transform time interval arrays into "readable" arrays
  for (let key in interval_obj) {
    const interval_params = interval_obj[key];
    let interval_total = "";

    // If request object does not have keys, continue
    let skip_key = false;
    for (let i in interval_obj[key].keys) {
      if (!(interval_obj[key].keys[i] in req_obj)) {
        skip_key = true;
      }
    }
    if (skip_key === true) continue;

    let arr_len = req_obj[interval_params.keys[0]].length;

    for (let i = 0; i < arr_len; ++i) {
      // Setup array with i-th element
      let arr = [];
      for (let m in interval_params.keys) {
        let key = interval_params.keys[m];
        arr.push(req_obj[key][i]);
      }
      interval_total = interval_params.reduce_function(
        arr,
        interval_total,
        i >= arr_len - 1
      );
    }
    new_req_obj[key] = interval_total;
  }
  // "el" should be the name of a property in calc_result.
  // This property should be an object, and it should be
  // translated
  console.log(`language is: ${language}`);
  if (language !== undefined && language !== "" && language !== false) {
    for (let el in translate_arr) {
      const obj = calc_result[translate_arr[el]];
      let new_obj = {};
      let translated_key = "";
      for (let key in obj) {
        try {
          translated_key = langSelect(TEXT["GRAPH"][key], language);
        } catch (err) {
          console.log(`Error translating: ${err}`);
          translated_key = key;
        }
        new_obj[translated_key] = obj[key];
      }
      new_calc_result[translate_arr[el]] = new_obj;
    }
  }

  return [new_req_obj, new_calc_result];
}




export const pass_regex =
  /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d\*\+\#\~\[\]\.\_\-\?]{8,}$/;
