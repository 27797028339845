<template>
    <BaseIcon v-bind="$props">
        <svg :width="this.width + 'px'" :height="this.height + 'px'" :stroke-width="this.strokeWidth + 'px'" viewBox="0 0 24 24" :fill="this.fill" xmlns="http://www.w3.org/2000/svg" :stroke="this.stroke">
            <path d="M7 9l5 3.5L17 9" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M2 17V7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2z"></path>
        </svg>
    </BaseIcon>
</template>

<script>
import BaseIcon from "@/components/SVG/BaseIcon.vue"
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "SVGEmail",
    components: {
        BaseIcon,
    },
    mixins: [SVGMixin]
}
</script>

<style>
</style>