import {
  authLogin,
  authLogout,
  refreshToken,
  trySecureAxios,
} from "@/util/authutil.js";

import router from "@/router/index.js";

export const auth = {
  namespaced: true,
  // initial state: not logged in , no tokens.
  // A login attempt is done at app.created(), by trying thenpm refresh token.
  // If that token is still valid, we are logged in.
  state: {
    announcements: [],
    loggedIn: false,
    tokens: { accessToken: "", refreshToken: "" },
    loginReady: false, // set to false at start, once token is checked, set to true
    logo: "",
    loggedInAsGuest: false,
    saved_calculations: [],
    last_read_calculations: localStorage.getItem("last_read_notification") || Date.parse("1970-01-01T00:00:00.000Z"),
    colors: [
      // some blue, pool themed colors
      "#374260",
      "#656464",
      "#548397",
      "#5D9B9B",
      "#EBF1F4",
    ],
    super_user_logo: "",
    layouts_list: [],
    pdf_layouts_list: [],
    user: {
      name: null, // username
      sub_users: 0,
      max_users: 0,
      // named this 'clients' to prevent confusion using 'state.user.users' etc
      clients: [],
      client_range: [], // Which clients are loaded? e.g., [50, 99]; users no 50 to 99
      client_page_size: 10,
      language: "en",
    },
    // Some "sub"clients the current user has
  },
  actions: {
    // Login action:
    //  -> authservice.login: send POST to API w {name, pwd}
    //     and save tokens in localstorage
    //  -> loginSuccess: set authenticated to true, clear localstorage
    login({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        authLogin(user, rootState.referrer).then(
          (response) => {
            commit("loginSuccess", response);
            if (response.user) commit("setUser", response.user);
            if (response.accessToken && response.refreshToken) {
              commit(
                "updateTokens",
                JSON.stringify({
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                })
              ); // End commit "updateTokens"
            }
            router.push("/");
            resolve(response);
          },
          (error) => {
            commit("loginFailure");
            reject(error);
          }
        );
      });
    },
    loginAsGuest({ commit }) {
      commit("loginAsGuest");
      // parse route param sharedid to view route
      // console.log("router.currentRoute.value.params.sharedid: " + router.currentRoute.value.params.sharedid);
      router.push("/view/" + router.currentRoute.value.params.sharedid);
    },
    setLastReadNotifications({ commit }, date) {
      commit("setLastReadNotifications", date);
    },
    logout({ commit, dispatch }) {
      authLogout();
      // commit("socket/disconnectSocket", null, { root: true });

      commit("calc/clearLayout", {}, { root: true });
      commit("logout");
      commit("root_logout", {}, { root: true });
      router.push("/login");
      commit("clear_calc", {}, { root: true });
    },
    fetchAccessToken({ commit }) {
      commit("updateTokens", localStorage.getItem("userTokens"));
    },

    tokenRefresh({ commit }) {
      return new Promise((resolve, reject) => {
        const tokendata = refreshToken()
          .then((data) => {
            commit("updateTokens", JSON.stringify(data));
            //return data;
            return resolve(data);
          })
          .catch((err) => {
            commit("loginReady");
            commit("tokenError");
            commit("socket/disconnectSocket", null, { root: true });
            //throw new Error("[store] -- auth module: tokenRefresh failed: " + err);
            return reject(err);
          });
      }); // end call to refreshtoken
    }, // End tokenRefresh
    loginTokenRefresh({ commit }) {
      const tokendata = refreshToken()
        .then((data) => {
          commit("updateTokens", JSON.stringify(data));
          commit("loginSuccess");
          commit("loginReady");
          const tryUser = data.user ? data.user : "";
          if (tryUser) commit("setUser", tryUser);
          router.push("/");
        })
        .catch((err) => {
          commit("loginFailure");
          commit("socket/disconnectSocket", null, { root: true });
          commit("loginReady");
        }); // end call to refreshtoken
    }, // End loginTokenRefresh
    // getUsers: get my (sub)clients
    getUsers({ commit }, options) {
      /*
       * trySecureAxios:
       * method::String
       * url::String
       * payload::String
       * retries::Int
       */
      let res = trySecureAxios("post", "rest/getClients/", options, 5)
        .then((data) => {
          let clients = data.data;
          let req_obj = {
            clients: clients,
            client_range: [0, 0],
          };

          // Set client_range in req_obj, to save it to the store
          if (options.start_index !== false && options.end_index !== false) {
            req_obj["client_range"][0] = options.start_index;
            req_obj["client_range"][1] = options.end_index;
          }
          commit("setClients", req_obj);
          return req_obj;
        })
        .catch((err) => {
          console.error("Could not get users. Error: " + err);
          return false;
        });
    },
    fetchCalculations({ commit }, options) {
      let res = trySecureAxios("get", "rest/getSharedCalculations/", options, 5)
        .then((data) => {
          commit("setCalculations", data.data,);
          return data.data;
        })
        .catch((err) => {
          console.error("Could not get calculations. Error: " + err);
          return false;
        });
    },
    getPrefs({ commit, rootState }) {
      trySecureAxios("get", "rest/getPrefs/", {}, 3)
        .then((data) => {
          if (data.data.default)
            commit("setLogo", rootState.baseAPIUrl + data.data);
          else commit("setLogo", data.data);
          commit("setColors", data.data.colors);
          return true;
        })
        .catch((error) => { });
    },
    getAnnouncements({ commit }) {
      trySecureAxios("get", "rest/getAnnouncements/", {}, 3)

        .then((data) => {
          commit("setAnnouncements", data.data);
          return true;
        })
        .catch((error) => { });
    },
    async resetPassword({ commit }, user) {

      let response = false;
      try {
        response = await trySecureAxios("post", "auth/resetpass", user, 5);
      } catch (error) {
        console.log(error);
        throw { response: error, message: "User/Email was not found" };
      }
      if (response) {
        return response;
      }

    },

    async requestPasswordChange({ commit }, resetObj) {
      let response = false;
      try {
        response = await trySecureAxios("post", "auth/requestpasschange", resetObj, 5);

      } catch (error) {
        throw error;
      }
      if (response) {
        return response;
      }
    },
    async editUser({ commit }, newUser) {
      let req_data = {
        _id: newUser.id,
        new_data: newUser,
      };
      let reqres;
      try {
        reqres = await trySecureAxios("post", "rest/editUser", req_data, 2);
      } catch (error) {
        return false;
      }
      return reqres;
    },
    reset({ commit }) {
      commit("tokenError");
    },
    async addUser({ commit }, newUser) {
      let response = false;
      try {
        response = await trySecureAxios("POST", "rest/addUser", newUser, 5);
      } catch (error) {
        throw error;
      }
      if (response) {
        return response;
      }
    },
    async deactivateUser({ commit }, user) {
      let response = false;
      try {
        let clientData = {
          username: user.username,
          _id: user.id,
          active: false,
        };
        response = await trySecureAxios(
          "POST",
          "rest/setUserActive",
          clientData,
          5
        );
        return response;
      } catch (error) {
        return false;
      }
    },
    async getLayouts({ commit }) {
      await trySecureAxios("get", "rest/getLayouts/", {}, 3)
        .then((data) => {
          commit("setLayouts", data.data);
          return true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async activateUser({ commit }, user) {
      let response = false;
      try {
        let clientData = {
          username: user.username,
          _id: user.id,
          active: true,
        };
        response = await trySecureAxios(
          "POST",
          "rest/setUserActive",
          clientData,
          5
        );
        commit("activateClient", clientData);
        return response;
      } catch (error) {
        return false;
      }
    },

    setLogo({ commit }, payload) {
      // Set logo in the store
      commit("setLogo", payload);
    },
    setLanguage({ commit }, lan) {
      //  console.log("setLanguage", lan);
      commit("setLanguage", lan);
    },
    setColors({ commit }, colors) {
      commit("setColors", colors);
    },
  }, // End actions
  mutations: {
    loginAsGuest(state) {
      state.loggedInAsGuest = true;
    },
    setLastReadNotifications(state, data) {
      state.last_read_calculations = data;
    },
    setAnnouncements(state, data) {
      state.announcements = data;
    },
    setCalculations(state, data) {
      //   console.log("setCalculations", data);
      state.saved_calculations = data;
    },
    setLayouts(state, data) {
      state.layouts_list = data.layouts;
      state.pdf_layouts_list = data.pdf_layouts;
    },

    setLanguage(state, lan) {
      state.user.language = lan;
    },
    setClients(state, data) {
      if (data.clients.users) {
        state.user.clients = data.clients.users;
      }
      if (data.clients.sub_users !== undefined) {
        state.user.sub_users = data.clients.sub_users;
      }
      if (data.clients.total_users) {
        state.user.total_users = data.clients.total_users;
      }
      if (data.clients.admin) {
        state.user.admin = data.clients.admin;
      }
      if (
        data.client_range &&
        data.client_range[0] !== undefined &&
        data.client_range[1] !== undefined
      )
        state.user.client_range = data.client_range;
    },
    addClient(state, data) {
      let newuser = {
        username: data.username,
        email: data.email,
        super_user: data.super_user,
        _id: data._id,
      };
      state.user.clients.push(newuser);
    },
    removeClient(state, data) {
      state.user.clients = state.user.clients.filter((value, index, arr) => {
        return value.username !== data.username;
      });
    },
    setUser(state, data) {
      for (let key in data) state.user[key] = data[key];
      if (data["logo"]) state.logo = data["logo"];
    },
    loginReady(state) {
      state.loginReady = true;
    },
    loginSuccess(state) {
      state.loggedIn = true;
    },
    loginFailure(state) {
      state.loggedIn = false;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = {
        name: null,
        sub_users: 0,
        max_users: 0,
        clients: [],
        client_range: [],
        client_page_size: 10,
        language: "en",
      };
      state.logo = "";
      state.super_user_logo = "";
      state.colors = [
        "#374260",
        "#656464",
        "#548397",
        "#5D9B9B",
        "#EBF1F4",
      ];
      state.tokens.accessToken = "";
      state.tokens.refreshToken = "";
    },
    // Use this when a refresh token is found to be invalid in a logged-in session
    tokenError(state) {
      //state.loggedIn = false;
      //state.tokens.accessToken = null;
      //state.tokens.refreshToken = null;
      //       if (router.currentRoute.name !== "Login" && router.currentRoute.name !== "Root") {
      //   router.push("/");
      // }
    },
    setLogo(state, obj) {
      if (obj.logo) state.logo = obj.logo;
      if (obj.super_user_logo) state.super_user_logo = obj.super_user_logo;
    },
    setColors(state, obj) {
      if (obj) state.colors = obj;
    },
    updateTokens(state, tokens) {
      let objtokens = tokens;
      try {
        objtokens = JSON.parse(objtokens);
      } catch (err) {
        // do nothing on error
      }
      if (state.tokens == null || state.tokens == undefined || !state.tokens) {
        state.tokens = {};
      }
      state.tokens.accessToken = objtokens.accessToken;
      state.tokens.refreshToken = objtokens.refreshToken;
      localStorage.setItem("userTokens", JSON.stringify(state.tokens));
    },
  }, // End mutations
  getters: {
    getAccessToken(state) {
      return state.tokens.accessToken;
    },
    getRefreshToken(state) {
      return state.tokens.refreshToken;
    },
    isLoggedIn(state) {
      return state.LoggedIn;
    },
  },
};
