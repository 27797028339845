
<template>
    <div v-if="src == undefined" :class="class" :style="style"></div>

    <v-lazy-image
        v-else-if="src.substring(src.length - 4) == '.png' || src.substring(src.length - 4) == '.jpg' || src.substring(src.length - 5) == '.jpeg' || src.substring(src.length - 4) == '.gif' || src.substring(src.length - 4) == '.svg' || src.substring(src.length - 5) == '.webp' || src.substring(src.length - 4) == '.ico' || src.substring(src.length - 4) == '.bmp'"
        :class="class" :src="formatImgUrl(src)" :alt="name" :style="style" />

    <v-lazy-image v-else-if="src.substring(0, 10) == 'data:image'" :class="class" :src="src" :alt="name" />
</template>


<script>
//Component for displaying images coming from the backend
import { langSelect, getBaseUrl, formatImgUrl } from "@/util/util";
import VLazyImage from "v-lazy-image";

export default {
    name: 'ImageComponent',
    components: {
        VLazyImage
    },
    props: {
        src: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        class: {
            type: String,
            required: false
        },
        style: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        langSelect,
        getBaseUrl,
        formatImgUrl,
    },
    mounted() {
        if ((name.substring(0, 6) == 'colour' || name.substring(0, 5) == 'color') && this.src.substring(0, 10) == 'data:image') {
            console.error("ImageComponent: image is base64 ")
        }
    }
}

</script>
