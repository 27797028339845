const SVGMixin = {
    props: {
        stroke: {type: String, default: "currentColor"},
        fill: {type: String, default: "transparent"},
        width: {type: Number, default: 24},
        height: {type: Number, default: 24},
        strokeWidth: {type: Number, default: 1.5},
        iconName: {type: String, default: undefined},
        tooltip: {type: String, default: ''}
    },
}

export default SVGMixin