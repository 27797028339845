// See https://v3-migration.vuejs.org/breaking-changes/events-api.html#event-bus

import e from 'tiny-emitter'
let emitter = new e()

export function resetEmitter() {
  emitter = new e()
}

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}