<template>
    <div class="icon-wrapper">
        <div class="tooltip" v-if="tooltip">
            {{tooltip}}
        </div>
        <slot></slot>
    </div>
</template>

<script>
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "BaseIcon",
    props: {
        iconName: {type: String, default: undefined},
        tooltip: {type: String, default: false},
    },
    mixins: [SVGMixin]
}
</script>

<style scoped>
.icon-wrapper {
    position: relative;
    display: inline-flex;
}
.tooltip {
    visibility: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 35%;
    opacity: 0;
    width: max-content;
    transform: translateY(calc(-5px - 100%));
    transition: all 0.2s;
    border-radius: var(--std-radius) var(--std-radius) var(--std-radius) 0;
    background-color: darkgray;
    color: white;
    padding: calc(var(--std-padding) / 2);
    font-size: 0.85rem;
}
.tooltip::after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    bottom: -6px;
    border-top: solid darkgray 6px;
    border-right: solid transparent 6px;
}
.icon-wrapper:hover > .tooltip {
    transition-delay: 1s;
    visibility: visible;
    transform: translateY(calc(-12px - 100%));
    opacity: 1;
}
</style>