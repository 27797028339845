<template>
    <BaseIcon v-bind="$props">
        <svg :width="this.width + 'px'" :height="this.height + 'px'" :stroke-width="this.strokeWidth + 'px'" viewBox="0 0 24 24" :fill="this.fill" xmlns="http://www.w3.org/2000/svg" :stroke="this.stroke">
            <path d="M2 20v-1a7 7 0 017-7v0" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M15.804 12.313a1.618 1.618 0 012.392 0v0c.325.357.79.55 1.272.527v0a1.618 1.618 0 011.692 1.692v0c-.023.481.17.947.526 1.272v0c.705.642.705 1.75 0 2.392v0c-.356.325-.549.79-.526 1.272v0a1.618 1.618 0 01-1.692 1.692v0a1.618 1.618 0 00-1.272.526v0a1.618 1.618 0 01-2.392 0v0a1.618 1.618 0 00-1.272-.526v0a1.618 1.618 0 01-1.692-1.692v0a1.618 1.618 0 00-.527-1.272v0a1.618 1.618 0 010-2.392v0c.357-.325.55-.79.527-1.272v0a1.618 1.618 0 011.692-1.692v0c.481.023.947-.17 1.272-.527v0z"></path>
            <path d="M15.364 17l1.09 1.09 2.182-2.18M9 12a4 4 0 100-8 4 4 0 000 8z" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </BaseIcon>
</template>

<script>
import BaseIcon from "@/components/SVG/BaseIcon.vue"
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "SVGVerifiedUser",
    components: {
        BaseIcon,
    },
    mixins: [SVGMixin]
}
</script>

<style scoped>
</style>