<template>
    <BaseIcon v-bind="$props">
        <svg :width="this.width + 'px'" :height="this.height + 'px'" :stroke-width="this.strokeWidth + 'px'" viewBox="0 0 24 24" :fill="this.fill" xmlns="http://www.w3.org/2000/svg" :stroke="this.stroke">
            <path d="M3 21h18M12.222 5.828L15.05 3 20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 00-.293.707v4.536h4.536a1 1 0 00.707-.293l5.607-5.607m-4.95-4.95l4.95 4.95" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </BaseIcon>
</template>

<script>
import BaseIcon from "@/components/SVG/BaseIcon.vue"
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "SVGEDit",
    components: {
        BaseIcon,
    },
    mixins: [SVGMixin]
}
</script>

<style scoped>
</style>