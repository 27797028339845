import { getInputValues } from "@/calculate.js";
import eventbus from "@/eventbus";
import { trySecureAxios } from "@/util/authutil.js";
import resetWatchVariables from "@/util/resetWatchVariables";

export const calc = {
  namespaced: true,
  // initial state: not logged in , no tokens.
  // A login attempt is done at app.created(), by trying the refresh token.
  // If that token is still valid, we are logged in.
  state: {
    calc_result: {},
    calculated_inputs: null,
    progress: 0,
    calculating: false,
    errmsg: "",
    ready: false,
    pdf_busy: false,
    pdf_result: {},
    shared_id: "",
    pdf_layout: {},
    // "inputs" gets pulled from DB and updated with values from inputs
    inputs: [],
    // repeatInputs to save generated repeatable inputs
    repeatInputs: {},
    // hiddenInputs inputs that are hidden in the UI, but still need to be sent to the server
    hiddenInputs: {},
    // last_calc_request is to be passed to the PDF server, to generate pdfs
    last_calc_request: {},
    // All_products: contains all products, categorized.
    // e.g.: {"covers":[],...}
    all_products: {},
    // used to get chosen products, for e.g., pdf requests
    chosen_products: {},
    calculated_products: {},
    outputs: [],
    colors: [
      "rgba(54, 162, 235, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ],
    last_saved_calc: null,
  },
  actions: {
    setCalculatedInputs({ commit, state }, obj) {
      commit("setCalculatedInputs", obj);
    },
    updateRepeatInput({ commit }, obj) {
      commit("updateRepeatInput", obj);
    },
    updateChosenProducts({ commit }, obj) {
      commit("updateChosenProducts", obj);
    },
    async getInputs({ commit, state }) {
      await trySecureAxios("get", "rest/getLayout", {}, 3)
        .then((data) => {
          commit("setLayout", data.data.layout);
          commit("setPdfLayout", data.data.pdf_layout);
        })
        .catch((error) => {
          console.error("Error setting page layout" + error);
        });
    },

    async shareCalculation({ commit, state }, obj) {
      let isFull = false;
      let res = await trySecureAxios(
        "post",
        "rest/shareCalculation",
        {
          name: obj.name,
          crash: obj.isCrash,
          input: {
            inputs: state.inputs,
            hiddenInputs: state.hiddenInputs,
            repeatInputs: state.repeatInputs,
            outputs: state.outputs,
            last_calc_request: state.last_calc_request,
            watchVariables: state.watchVariables,
            pdf_layout: state.pdf_layout,
            logo: obj.logo,
          },
          output: obj.isCrash ? null : state.calc_result,
        },
        3
      ).catch((error) => {
        console.error("Error sharing calculation" + error);
        console.error(error);
        isFull = true;
      });
      if (isFull) {
        return false;
      }
      let id = res.data.id;
      // copy url to clipboard

      let url = window.location.origin + "/calculation/" + id;
      state.last_saved_calc = url;
      navigator.clipboard.writeText(url);

      return true;
    },
    setSharedId({ commit, state }, id) {
      commit("setSharedId", id);
    },
    async setNewLayout({ commit, state }, layout) {
      let response = false;
      try {
        response = await trySecureAxios("post", "rest/setLayout", layout, 5);
      } catch (error) {
        //console.log(error);
        throw { response: error, message: "User/Email was not found" };
      }
      await this.dispatch("calc/getInputs");
    },
    async updateCalculationName({ commit, state }, obj) {
      let id = obj.id;
      let name = obj.name;
      let res = await trySecureAxios(
        "post",
        "rest/renameSharedCalculation",
        { id: id, name: name },
        3
      );

      await this.dispatch("auth/fetchCalculations");
    },

    async deleteCalculation({ commit, state }, id) {
      let res = await trySecureAxios(
        "post",
        "rest/deleteSharedCalculation",
        { id: id },
        3
      );

      await this.dispatch("auth/fetchCalculations");
    },
    async getProductsFromSharedCalc({ commit, state }, id) {
      let res = await trySecureAxios(
        "post",
        "rest/getProductsFromLayout/",
        { id: id },
        3
      );
      commit("setProducts", {
        productlist: res.data,
      });
    },
    async getSharedCalc({ commit, state }, { id, isGuest }) {
      await trySecureAxios("get", "rest/getSharedCalculation/" + id, {}, 3)
        .then((data) => {
          if (data.data.output !== undefined && data.data.output !== null) {
            commit("setResult", data.data.output);
          }
          //  console.log("data.data", data.data);
          if (data.data.input.logo && isGuest) {
            this.dispatch("auth/setLogo", { logo: data.data.input.logo });
          }
          let inputs = data.data.input.inputs;
          let outputs = data.data.input.outputs;
          if (isGuest) {
            inputs.pop();
            outputs.pop();
          }
          state.inputs = inputs;
          state.last_calc_request = data.data.input.last_calc_request;
          state.hiddenInputs = data.data.input.hiddenInputs;
          state.repeatInputs = data.data.input.repeatInputs;
          state.outputs = outputs;
          state.watchVariables = data.data.input.watchVariables;
          commit("setCalculatedInputs", getInputValues());
          resetWatchVariables.resetWatchVariables(state.watchVariables);
          state.pdf_layout = data.data.input.pdf_layout;
        })
        .catch((error) => {
          console.error("Error getting shared calculation" + error);
        });
    },
    async getProducts({ commit, state }) {
      let res = await trySecureAxios("post", "rest/getProducts/", {}, 3);

      commit("setProducts", {
        productlist: res.data,
      });
    }, // End getProducts
    setPDFError({ commit }, error) {
      commit("setPDFState", { error: error, ignore_busy: true });
    },
  }, // End actions
  mutations: {
    setSharedId(state, id) {
      state.shared_id = id;
    },

    updateChosenProducts(state, obj) {
      state.chosen_products[obj.inputId] = obj.product;
    },
    /**
     * setResult: updates store state when calculation
     * finishes.
     */
    setResult(state, result) {
      // console.log("Setting result", result);
      if (result == -1) return false;
      state.calc_result = result;
      state.ready = true;
      // Emit event on bus - homepage should jump to results
      eventbus.$emit("calcReady");
    },
    setLayout(state, layout) {
      state.watchVariables = layout.variables;
      resetWatchVariables.resetWatchVariables(state.watchVariables);
      state.inputs = layout.input;
      state.outputs = layout.output;
      state.hiddenInputs = layout.hiddenInput;
    },
    setPdfLayout(state, pdf_layout) {
      state.pdf_layout = pdf_layout;
    },
    clearLayout(state, layout) {
      state.inputs = [];
      state.outputs = {};
      state.hiddenInputs = {};
      state.watchVariables = {};
    },

    setProgress(state, progress) {
      state.progress = progress;
    },
    calcUpdate(state, obj) {
      try {
        state.calculating = obj.state;
        if (obj.state === false) state.progress = 0;

        state.errmsg = obj.error ? obj.error : "";
        if (obj.error) {
          state.ready = false;
          //  console.log("Error in calculation request: " + obj.error);
        }
      } catch (error) {
        console.error();
      }
    },
    setPDFState(state, pdf_obj) {
      if ("error" in pdf_obj) {
        state.pdf_error = pdf_obj.error.error || pdf_obj.error;
        // if ignore_busy is set, don't set pdf_busy to false
        if (!pdf_obj.ignore_busy) state.pdf_busy = false;
      }
      if ("position" in pdf_obj) {
        state.pdf_position = pdf_obj.position;
        state.pdf_busy = true;
      }
      if (pdf_obj.state) {
        state.pdf_busy = pdf_obj.state;
      }
    },
    setPDFResult(state, pdf) {
      state.pdf_result = pdf;
      state.pdf_busy = false;
    },

    updateRepeatInput(state, obj) {
      if (state.repeatInputs == undefined) {
        state.repeatInputs = {};
      }
      if (!state.repeatInputs[obj.name]) state.repeatInputs[obj.name] = {};

      state.repeatInputs[obj.name].inputs = obj.inputs
        ? obj.inputs
        : state.repeatInputs[obj.name].inputs;
      state.repeatInputs[obj.name].enabled = obj.enabled;
    },
    setProducts(state, prod_obj) {
      if (!state.all_products) state.all_products = {};

      state.all_products = prod_obj.productlist;

      return true;
    },
    setLastCalcRequest(state, obj) {
      state.ch;
      state.last_calc_request = obj;
    },
    setCalculatedInputs(state, obj) {
      state.calculated_inputs = obj;
      // copy calculated inputs to inputs
      state.calculated_products = JSON.parse(
        JSON.stringify(state.chosen_products)
      );
    },
  }, // End mutations
};
