<template>
    <BaseIcon v-bind="$props">
        <svg :width="this.width + 'px'" :height="this.height + 'px'" :stroke-width="this.strokeWidth + 'px'" viewBox="0 0 24 24" :fill="this.fill" xmlns="http://www.w3.org/2000/svg" :stroke="this.stroke">
            <path d="M17 10h3m3 0h-3m0 0V7m0 3v3M1 20v-1a7 7 0 017-7v0a7 7 0 017 7v1M8 12a4 4 0 100-8 4 4 0 000 8z" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </BaseIcon>
</template>

<script>
import BaseIcon from "@/components/SVG/BaseIcon.vue"
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "SVGAddUser",
    components: {
        BaseIcon,
    },
    mixins: [SVGMixin]
}
</script>

<style scoped>
</style>