import { langSelect } from "@/util/util";
import io from "socket.io-client";
import store from ".";

const NOTIFICATION_TEXT = require("@/util/tr.json")["NOTIFICATION"];

export const socket = {
  namespaced: true,
  state: {
    socket_ready: false,
    progress: 0,
    calculating: false,
    socket: null,
    retry_limit: 50,
    conn_retries: 0,
    errmsg: null,
  },
  actions: {
    requestCalculation({ state, rootState, commit }, inputobj) {
      state.socket.emit("calc_request", inputobj);
      commit("calc/setLastCalcRequest", inputobj, { root: true });
    },

    loginSocket({ state, dispatch, commit, rootState }) {
      try {
        // console.log(
        //   "Trying to connect to socket using access token: " +
        //   rootState.auth.tokens.accessToken
        // );
        state.socket = io(rootState.baseAPIUrl, {
          "force new connection": true,
          path: "/wsock/",
          transports: ["websocket"],
          query: {
            token: rootState.auth.tokens.accessToken,
          },
        }); // end socket w query
        state.conn_retries = 0;
        dispatch("setSocketMessages");
      } catch (err) {
        // console.log("[store] -- socket: loginSocket: error: ");
        console.table(err);
        throw Error(
          "Failed to connect to socket from store using existing token."
        );
      }
    },
    newTokenSocket({ dispatch, commit, state, rootState }) {
      // console.log(
      //   `[store] -- socket: newTokenSocket() called. -- tokens are: ${JSON.stringify(
      //     rootState.auth.tokens
      //   )}`
      // );
      if (!rootState.auth.tokens.refreshToken) {
        // console.log(`tokens can't be found; not retrying!`);
        return false;
      } else {
        state.conn_retries++;
        if (state.conn_retries > state.retry_limit) {
          // console.log(
          //   "[store] -- socket: exceeded retry limit. Could not set socket."
          // );
          commit(
            "socketServerError",
            langSelect(
              NOTIFICATION_TEXT["socket_error"],
              rootState.auth.user.language
            )
          );
          state.socket = null;
          dispatch("auth/logout");
          return false;
        }
        let data;
        store.dispatch("auth/tokenRefresh").then((newtokens) => {
          data = newtokens;
          dispatch("loginSocket");
        });
      }
    }, // End newTokenSocket();
    setSocketMessages({ commit, dispatch, state, rootState }) {
      state.socket
        .on("error", (err) => {
          // console.log("something went wrong with socket.");
          // console.log("  -> error: " + err);
          commit("disconnectSocket");
          commit(
            "socketServerError",
            langSelect(
              NOTIFICATION_TEXT["socket_error"],
              rootState.auth.user.language
            )
          );
          commit("socketSetError");
        })
        .on("connect_error", (err) => {
          console.warn("STORE/SOCKET: CONNECTION ERROR");
          // On connection error: refresh token, retry connect
          commit("disconnectSocket");
          commit(
            "socketServerError",
            langSelect(
              NOTIFICATION_TEXT["socket_error"],
              rootState.auth.user.language
            )
          );
          dispatch("newTokenSocket");
        })
        .on("serverror", (data) => {
          commit("disconnectSocket");

          console.warn("STORE/SOCKET: SERVER ERROR - data: " + data.toString());

          commit(
            "calc/calcUpdate",
            { state: false, error: data },
            { root: true }
          );
          setTimeout(() => {
            dispatch("newTokenSocket");
          }, 1500);
        })
        .on("connect", () => {
          commit("socketReady");
        })
        .on("connection", () => {
          commit("socketReady");
        })
        .on("disconnect", (reason) => {
          console.warn("STORE/SOCKET: DISCONNECTED. REASON: " + reason);
          commit("disconnectSocket");
          commit(
            "socketServerError",
            langSelect(
              NOTIFICATION_TEXT["socket_error"],
              rootState.auth.user.language
            )
          );
          dispatch("newTokenSocket");
        })
        .on("calc_update", (obj) => {
          commit("calc/calcUpdate", obj, { root: true });
          if (obj.result) {
            // console.log("Result of calculation: ");
            // console.log(obj.result);
            commit("calc/setResult", obj.result, { root: true });
            // disconnect socket
            commit("disconnectSocket");
          }
        })
        .on("calc_progress", (prog_obj) => {
          commit("calc/setProgress", prog_obj.calc_progress, { root: true });
          commit("setProgress", prog_obj.calc_progress);
        })
        .on("pdf_error", (err) => {
          // console.log("PDF ERROR: " + err);
          commit(
            "calc/setPDFState",
            { state: false, error: err },
            { root: true }
          );
        })
        .on("pdf_update", (obj) => {
          // console.log("PDF UPDATE: " + JSON.stringify(obj));
          commit("calc/setPDFState", obj, { root: true });
        })
        .on("pdf_result", (obj) => {
          //  console.log("PDF RESULT: " + obj);
          // console.log(obj);
          commit("calc/setPDFResult", obj, { root: true });
        });
    },
    disconnect({ commit }) {
      commit("disconnectSocket");
    },
  }, // End actions
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
      state.socket_ready = true;
      state.conn_retries = 0;
      state.errmsg = "";
    },
    socketReady(state) {
      state.socket_ready = true;
      state.conn_retries = 0;
      state.errmsg = "";
    },
    setProgress(state, prog) {
      state.progress = prog;
    },
    socketSetError(state) {
      state.socket = null;
      state.socket_ready = false;
      state.conn_retries = 0;
    },
    resetRetries(state) {
      state.conn_retries = 0;
    },
    disconnectSocket(state) {
      console.log("Disconnecting socket...");
      if (state.socket !== null) {
        state.socket.disconnect();
      }
      state.errmsg = "";
      state.socket = null;
      state.socket_ready = false;
      state.conn_retries = 0;
    },
    socketServerError(state, message) {
      state.errmsg = message;
      state.calculating = false;
    },
  }, // End mutations
};
