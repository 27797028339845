<template>
  <div :style="{
    '--color-mediumblue': accentColor,
    '--color': highlightColor,
    '--greenblue': accentColor,
  }
    " class="h-full relative  ">
    <Header v-if="$route.meta.header" :sidebar="$route.meta.sidebar" title="Energy Simulator"
      :logo_src="$store.state.auth.super_user_logo || $store.state.auth.logo"
      :backgroundColor="$store.state.auth.colors[0]" />

    <router-view />
  </div>
</template>

<script>

import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  data() {
  }, // end data function
  computed: {
    accentColor: function () {
      return this.$store.state.auth.colors[0];
    },
    highlightColor: function () {
      return this.$store.state.auth.colors[1];
    },
  }, // End computed
  methods: {}, // End methods
  created() {
    this.$store.dispatch("auth/loginTokenRefresh");
  },
  beforeUnmount() {
    this.$store.dispatch("socket/disconnect");
  },
}; // End export default
</script>

<style>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color);
  outline: 1px solid var(--color);
  border-radius: 5px;

}

#app {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-mediumblue);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color) var(--white);
}

*:hover {
  scrollbar-color: var(--color-mediumblue) var(--white);

}

body {
  /* no extra heig */
  height: 100vh;
  width: 100vw;
  overflow: hidden;

}

p {
  max-width: 100vw;
  overflow: hidden;
}
</style>
