import eventbus from "@/eventbus";

/**
 * Set up and keep track of variables as defined in the "layout" object;
 * example: "volume" should be pool_width, pool_length and pool_depth multiplied,
 * so the layout may keep:
 * "volume": {
 *     'inputBinds': [pool_width, pool_length, pool_depth],
 *     'operation': "multiply"
 * }
 */
export default {
    // "variables" should be an object, so passed by reference
    resetWatchVariables(variables) {
        // For every variable to periodically update:
        let fields = new Set();
        for (let varname in variables) {
            let variable = variables[varname];
            // Describe map function depending on "operation" type
            eventbus.$on(`valueRequest:${varname}`, () => {
                for (let i in variable.inputBinds)
                    eventbus.$emit(`valueRequest:${variable.inputBinds[i]}`);
            });


            /**
             * - Set "last_bind_values" to keep track of 'previous' values of inputs bound to variable,
             * - Bind input listeners
             */
            variable.last_bind_values = {};
            // For every input bound to this variable
            for (let k in variable.inputBinds) {
                k = variable.inputBinds[k];
                // Set initial values
                variable.last_bind_values[k] = ["multiply", "divide"].includes(variable.operation) ? 1 : 0;
                if (variable.series) {
                    variable.last_bind_values[k] = {};
                }

                // Keep track of the fields that we need to listen to:
                fields.add(k);
            } // End for k in input binds
        } // End for varname in variables
        for (let k of fields) {

            eventbus.$on(`inputUpdate:${k}`, (object) => {

                for (let varname in variables) {
                    let variable = variables[varname];
                    //check if the variable is bound to the input that was updated
                    if (!variable.inputBinds.includes(k)) {
                        continue;
                    }
                    // Set new value
                    variable.last_bind_values[k] = object.value;
                    // Recompute new value from array
                    let emit_val;
                    if (variable.initialValue == undefined) {
                        emit_val = Object.values(variable.last_bind_values).reduce(
                            getReduceFunc(variable.operation),);

                    } else {
                        emit_val = Object.values(variable.last_bind_values).reduce(
                            getReduceFunc(variable.operation),
                            variable.initialValue
                        );
                        // emit new value
                    }

                    eventbus.$emit(`inputUpdate:${varname}`, { sender: varname, value: emit_val });

                }
            });
            eventbus.$on(`seriesUpdate:${k}`, (object) => {
                for (let varname in variables) {
                    let variable = variables[varname];
                    //check if the variable is bound to the input that was updated
                    if (!variable.series || !variable.inputBinds.includes(k)) {
                        continue;
                    }
                    // Set new value
                    // e.g. variable.last_bind_values["filter_start_hours"]["filter_start_hours_0"] = 14
                    variable.last_bind_values[k][object.sender] = object.value;
                    // Add up the values that are 2 deep
                    if (object["unset"] == true) {
                        delete variable.last_bind_values[k][object.sender];
                    }
                    let simplified = [] //array of values
                    for (let key in variable.last_bind_values) {
                        let acc = 0;
                        // loop over, e.g., filter_start_hours_0; filter_start_hours_1; ...
                        for (let key2 in variable.last_bind_values[key]) {
                            acc += variable.last_bind_values[key][key2];
                        }
                        simplified.push(acc);
                    }
                    let emit_val;
                    if (variable.checkinterval === true) {
                        emit_val = checkinterval(variable.last_bind_values) ? 1 : 0;
                    }
                    else if (variable.checkoverlap === true) {
                        emit_val = checkFilterIntervals(variable.last_bind_values) ? 1 : 0;
                    }
                    else if (variable.initialValue == undefined) {
                        emit_val = simplified.reduce(
                            getReduceFunc(variable.operation)
                        );
                    } else {
                        emit_val = simplified.reduce(
                            getReduceFunc(variable.operation), // reduce function
                            variable.initialValue
                        );
                    }
                    // emit new value
                    eventbus.$emit(`inputUpdate:${varname}`, { sender: varname, value: emit_val });
                }
            });
            // Now everything is ready, try to fetch some initial values:
            console.log("submitting valueRequest for valueRequest:", k);
            eventbus.$emit(`valueRequest:${k}`);
        }
    } // End resetWatchVariables
}

function checkinterval(variable) {
    let boundVariables = variable
    let innerStart = Object.values(boundVariables)[1];
    let innerEnd = Object.values(boundVariables)[3];
    let outerStart = Object.values(boundVariables)[0];
    let outerEnd = Object.values(boundVariables)[2];

    let innerInterVals = [];
    let outerInterVals = [];


    for (let i = 0; i < Object.keys(innerStart).length; i++) {
        innerInterVals.push([innerStart[Object.keys(innerStart)[i]], innerEnd[Object.keys(innerEnd)[i]]]);
    }

    for (let i = 0; i < Object.keys(outerStart).length; i++) {
        outerInterVals.push([outerStart[Object.keys(outerStart)[i]], outerEnd[Object.keys(outerEnd)[i]]]);
    }
    // if any of the intervals have the same start and end time, we need to treat them as a single interval
    innerInterVals = mergeIntervals(innerInterVals);

    outerInterVals = mergeIntervals(outerInterVals);

    let isHeatingWithinFilter = innerInterVals.every(heatingInterval => {
        return outerInterVals.some(filterInterval => {

            return heatingInterval[0] >= filterInterval[0] && heatingInterval[1] <= filterInterval[1];
        });
    });
    return isHeatingWithinFilter;
}
function checkFilterIntervals(filterIntervals) {

    const startTimes = Object.values(Object.values(filterIntervals)[0]);
    const endTimes = Object.values(Object.values(filterIntervals)[1]);

    // if theres only one interval, it can't overlap
    if (startTimes.length <= 1) {
        return true;
    }

    for (let i = 0; i < startTimes.length; i++) {
        for (let j = i + 1; j < startTimes.length; j++) {
            if (startTimes[i] < endTimes[j] && startTimes[j] < endTimes[i]) {
                return false;
            }
        }
    }

    return true;
}

function getReduceFunc(operation) {
    switch (operation) {
        case "multiply":
            return ((accumulator, currentValue) => Number(accumulator) * Number(currentValue));
        case "divide":
            return ((accumulator, currentValue) => Number(accumulator) / Number(currentValue));
        case "add":
            return ((accumulator, currentValue) => Number(accumulator) + Number(currentValue));
        case "subtract":

            return ((accumulator, currentValue) => Number(accumulator) - Number(currentValue));
    }
}

function mergeIntervals(intervals) {
    if (intervals.length <= 1) {
        return intervals;
    }
    intervals.sort((a, b) => a[0] - b[0]);

    let merged = [];
    let current = intervals[0];
    merged.push(current);
    for (let i = 1; i < intervals.length; i++) {
        let currentEnd = current[1];
        let nextBegin = intervals[i][0];
        let nextEnd = intervals[i][1];

        if (currentEnd >= nextBegin) {
            current[1] = Math.max(currentEnd, nextEnd);
        } else {
            current = intervals[i];
            merged.push(current);
        }
    }
    console.log("merged", merged);
    return merged;
}

