<template>
    <BaseIcon v-bind="$props">
        <svg :width="this.width + 'px'" :height="this.height + 'px'" :stroke-width="this.strokeWidth + 'px'" viewBox="0 0 24 24" :fill="this.fill" xmlns="http://www.w3.org/2000/svg" :stroke="this.stroke">
            <path d="M8.992 13h6" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M3.04 4.294a.496.496 0 01.191-.479C3.927 3.32 6.314 2 12 2s8.073 1.32 8.769 1.815a.496.496 0 01.192.479l-1.7 12.744a4 4 0 01-1.98 2.944l-.32.183a10 10 0 01-9.922 0l-.32-.183a4 4 0 01-1.98-2.944l-1.7-12.744zM3 5c2.571 2.667 15.429 2.667 18 0"></path>
        </svg>
    </BaseIcon>
</template>

<script>
import BaseIcon from "@/components/SVG/BaseIcon.vue"
import SVGMixin from "@/components/SVG/SVGMixin"

export default {
    name: "SVGBin",
    components: {
        BaseIcon,
    },
    mixins: [SVGMixin]
}
</script>