<template>
  <header class="flex items-center justify-between    px-5 md:px-24  bg-greenblue">

    <ImageComponent v-if="logo_src" class="h-full py-2 flex-1 object-contain object-left cursor-pointer" :src="logo_src"
      @click="this.$store.state.auth.loggedInAsGuest ? () => { } : navigate($event, '/home')"
      name="main company logo" />

    <h1 class="text-center h-full justify-center hidden items-center flex-1 text-3xl md:flex text-white cursor-pointer"
      @click="this.$store.state.auth.loggedInAsGuest ? () => { } : navigate($event, '/home')">{{ title }}
    </h1>

    <div class="profile flex-row flex h-full  items-center flex-1 justify-end text-white relative cursor-pointer"
      v-if="!this.$store.state.auth.loggedInAsGuest">
      <p class="text-xl pr-4">{{ $store.state.auth.user.name }}</p>
      <img class="h-1/2  text-white" :src="require('@/assets/user-circle-alt.svg')" alt="profile icon" />
      <ul class="profile-dropdown rounded-2xl   bg-color  cursor-pointer">
        <li class="p-5" @click="navigate($event, '/profile')">
          {{ langSelect(texts["my_profile"], language) }}
        </li>
        <li class="border-blue border-t mx-5"></li>
        <li class="p-5 Logout" @click="logout">
          {{ langSelect(texts["log_out"], language) }}
        </li>
      </ul>
    </div>
    <div class="flex-1" v-if="this.$store.state.auth.loggedInAsGuest"></div>
  </header>
</template>

<script>
import store from "@/store/index.js";
import InfoButton from "./InfoButton.vue";

import { resetEmitter } from "@/eventbus.js";
import { langSelect } from "@/util/util";
import ImageComponent from "./ImageComponent.vue";

const texts = require("@/util/tr.json")["HEADER"];

export default {
  name: "Header",
  components: {

    ImageComponent,
    InfoButton
  },
  props: {
    title: String,
    logo_src: String,
  },

  data() {
    return {
      texts,
      language: this.$store.state.auth.user.language,
    };
  },
  watch: {
    language(newLan, oldLan) {
      this.$store.dispatch("auth/setLanguage", newLan)
    },
  },
  computed: {
    username: () => {
      return store.state.auth.user.name || false;
    },

    links: () => {
      return store.state.links;
    },
    new_notifs: () => {
      let count = 0;
      for (let i = 0; i < store.state.auth.announcements.length; i++) {
        if (Date.parse(store.state.auth.announcements[i].date) > store.state.auth.last_read_calculations) {
          count++;
        }
      }
      return count;
    }
  }, // End computed
  methods: {
    langSelect,
    setLastReadNotification() {
      localStorage.setItem("last_read_notification", Date.now());
      store.dispatch("auth/setLastReadNotifications", Date.now());
    },
    logout: async () => {
      resetEmitter();
      store.dispatch("auth/logout");
    },
    navigate(event, target) {
      this.$router.push({ path: target });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  height: var(--header-height);

  width: 100%;
}








.profile-dropdown {
  visibility: hidden;
  top: 100%;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
}

.profile {
  cursor: default;
}

.profile:hover>.profile-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(10px);
}

.profile-dropdown:hover {
  visibility: visible;
  opacity: 1;
  transform: translateY(10px);
}







li.listitem:hover {
  background-color: var(--color-mediumblue);
  color: var(--white);
  cursor: pointer;
}

.latest-info {
  z-index: 2;
  position: absolute;
}

.warning {
  /* warning should be orange */
  color: #ff8c00;
}

/* small red box that shows how many unread notifications there are */
.notif-box {
  position: absolute;
  top: 2;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #fff;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.error {
  /* error should be red */
  color: #ff0000;
}

.section1 {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px rgba(50, 50, 50, 0.7);
}


.error-container {
  background-color: #ff0000;
  color: #fff;
}

.warning-container {
  background-color: #ff8c00;
  color: #fff;
}

.info-head {
  font-size: 1.3rem;
}

.info-body {
  font-size: 1rem;
  margin: 5px;
}
</style>
